import './App.css';

function App() {
  return (
    <div className="App">
      <h1> Welcome to <span class="red">Grace</span><span class="blue"> Infratech</span></h1>
      <hr></hr>
      <h2>Write mail to : Commerical@graceinfratech.com</h2>

      <h2>sanjeev@graceinfratech.com</h2>

      <hr></hr>
      <p>This website is underconstuction right now. Regarding technical support and suggestions, kindly mail to : abhi@graceinfratech.com</p>

    </div>
  );
}

export default App;
